"use client";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { type ReactNode, createContext, useContext, useState } from "react";
import { fallbackLng } from "../settings";

type TContext = {
  currentLanguage: string;
  changeLanguage: (value: string) => void;
  redirect: (pathname: string) => {
    pathname: string;
    query?: { lang: string };
  };
};
const defaultContext: TContext = {
  currentLanguage: fallbackLng,
  changeLanguage: () => {},
  redirect: () => ({ pathname: "", query: { lang: fallbackLng } }),
};

const LanguageContext = createContext(defaultContext);

export const LanguageProvider = LanguageContext.Provider;

export const LanguageConsumer = LanguageContext.Consumer;

export const LanguageWithProvider = ({
  value = fallbackLng,
  children,
}: {
  value: string;
  children: ReactNode;
}) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const [currentLanguage, setCurrentLanguage] = useState(
    searchParams.get("lang") || value,
  );

  const getNewUrl = (newLang: string) => {
    if (newLang === "en") return pathname;
    const url = `${pathname}?lang=${newLang}`;

    return url;
  };

  const changeLanguage = (value: string) => {
    const newUrl = getNewUrl(value);

    setCurrentLanguage(value);
    router.replace(newUrl, { scroll: false });
  };

  const redirect = (pathname: string) => {
    if (currentLanguage === "en") return { pathname };
    return { pathname, query: { lang: currentLanguage } };
  };

  return (
    <LanguageProvider value={{ currentLanguage, changeLanguage, redirect }}>
      {children}
    </LanguageProvider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }

  return context;
};
