"use client";
import { NextUIProvider } from "@nextui-org/system";
import type { ThemeProviderProps } from "next-themes";
import { ThemeProvider } from "next-themes";
import { useRouter } from "next/navigation";
import type React from "react";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import ReactQuery from "utils/providers/ReactQuery";
import "react-toastify/dist/ReactToastify.css";
import { LanguageWithProvider } from "utils/i18n/languageContext";

export interface ProvidersProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
}

export function Providers({ children, themeProps }: ProvidersProps) {
  const router = useRouter();
  return (
    <ReactQuery>
      <ThemeProvider {...themeProps}>
        <NextUIProvider navigate={(url) => router.push(url)}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            aria-label={undefined}
          />
          <Suspense fallback="loading...">
            <LanguageWithProvider value="en">{children}</LanguageWithProvider>
          </Suspense>
        </NextUIProvider>
      </ThemeProvider>
    </ReactQuery>
  );
}
