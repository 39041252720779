export const fallbackLng = "en";
export const defaultNS = "translation";
export const cookieName = "lang";
export const detailedLanguage = [
  {
    key: fallbackLng,
    name: "English",
    nativeName: "English",
  },
  {
    key: "es",
    name: "Spanish",
    nativeName: "Español",
  },
];

export const languages = detailedLanguage.map((lang) => lang.key);
export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS,
) {
  return {
    // debug: true,
    supportedLngs: languages,
    preload: ["en", "es"],
    fallbackLng,
    lng,
    detection: {
      order: ["querystring", "cookie", "navigator"],
      caches: [],
    },
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    // backend: {
    //   projectId: '01b2e5e8-6243-47d1-b36f-963dbb8bcae3'
    // }
  };
}
