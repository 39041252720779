import type { FC, ReactNode } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const ReactQuery: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQuery;
